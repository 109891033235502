import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ReactPhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { useSelector } from 'src/redux/store';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useDispatch } from 'react-redux';

// routes
import { useSearchParams, useRouter } from 'src/routes/hook';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import { endpoints, getFetcherPrams, postFetcher, postPramsFetcher1 } from 'src/utils/axios';
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGINPOS } from 'src/config-global';

// ----------------------------------------------------------------------

export default function UserLocationSelect({
  currentUser,
  open,
  onClose,
  loginData,
  onLocationSelect,
  userDetails,
}) {
  const { login } = useAuthContext();

  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const { enqueueSnackbar } = useSnackbar();

  const loadingSend = useBoolean();

  const [errorMsg, setErrorMsg] = useState('');

  const dispatch = useDispatch();

  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      first_name: currentUser?.first_name || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const reduxData = useSelector((state) => state.auth);
  console.log("Yooo Fire ", reduxData);
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;

  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const locations = loginData?.user?.usersLocationList || [];

  console.log('loginData', loginData);
  console.log('locations', locations);

  console.log('loginData', loginData?.user?.usersLocationList[0]?.location?.name);

  const onSubmit = handleSubmit(async (data) => {
    loadingSend.onTrue();
    // await login?.(loginData.username, loginData.password);
    // router.push(returnTo || PATH_AFTER_LOGIN);
  });

  // select location
  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    console.log('locationIdDetails', locationId);
    setSelectedLocationId(locationId);

    const selectedLocation = locations.find((item) => item.location.id === locationId);

    if (selectedLocation) {
      const locationName = selectedLocation.location.name;
      onLocationSelect(locationId, locationName);
    }
  };
  const loginFunc = async (event) => {
    if (selectedLocationId === null) {
      enqueueSnackbar('Please Select Your Branch', {
        variant: 'error',
        autoHideDuration: 5000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        sx: {
          width: '400px',
        },
      });
    } else {
      console.log('Fireloc', selectedLocationId);

      // loadingSend.onTrue();
      const res = await login?.(userDetails.username, userDetails.password);
      console.log('res', res);
      console.log('ROLE_POSROLE_POS', reduxData);
      if (reduxData?.user.roles[0] === "ROLE_POS") {
        router.push(PATH_AFTER_LOGINPOS);
      } else {
        router.push(PATH_AFTER_LOGIN);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 600 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 3, mt: 3 }}>
            Location Verification
          </Alert>

          <>
            <RHFSelect
              size="small"
              fullWidth
              name="location_id"
              onChange={handleLocationChange}
              label="Select Your Location"
              style={{ textAlign: 'left' }}
            >
              {locations.map((item) => (
                <MenuItem key={item.location.id} value={item.location.id}>
                  {item.location.name}
                </MenuItem>
              ))}
            </RHFSelect>
          </>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            onClick={loginFunc}
          >
            Go
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserLocationSelect.propTypes = {
  currentUser: PropTypes.object,
  loginData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onLocationSelect: PropTypes.object,
  userDetails: PropTypes.object,
};
