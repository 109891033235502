import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { endpoints, getFetcher, postFetcher, postPramsFetcher } from 'src/utils/axios';

let options = [];

export default function SearchOption({ onChange, newValue1 }) {
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    // Load the initial data on component mount
    loadData();
  }, []);

  const handleAutocompleteChange = (event, newValue) => {
    newValue1 = newValue;
    setValue(newValue);
    onChange(newValue);
  };

  const loadData = async () => {
    try {
      const response = await apiCall({ newInputValue: '' });

      if (response !== null) {
        response.map((item) => {
          options.push({ label: item.item_id, id: item.id, item: item.fields });
          return '';
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <br />
      <Autocomplete
        value={value}
        onChange={handleAutocompleteChange}
        inputValue={inputValue}
        onInputChange={async (event, newInputValue) => {
          setInputValue(newInputValue);
          const response = await apiCall({ newInputValue });

          // try{
            if (response !== null) {
              console.log("response",response);
              console.log("response.item",response);
              options = response.map((item) => ({
                label: item.item_id,
                id: item.id,
                item: item.fields,
              }));
            }
          // }catch(e){
          //   console.log("catch");
          //   if (response !== null) {
          //     console.log("response",response);
          //     console.log("response.item",response);
          //     options = response.map((item) => ({
          //       label: item.item_id,
          //       id: item.id,
          //     }));
          //   }
          // }
    
        }}
        id="controllable-states-demo"
        options={options}
        size="small"
        sx={{ width: 500 }}
        renderInput={(params) => <TextField {...params} label="Select Material Name" />}
      />
    </div>
  );
}

SearchOption.propTypes = {
  newValue1: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

async function apiCall(value) {
  const data = { item_id: value.newInputValue };
  const params = { item_id: value.newInputValue };
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const systemData = await postPramsFetcher(
      endpoints.systemSettings.systemMasterSearchList,
      JSON.stringify(data),
      params,
      config
    );
    return systemData;
  } catch (error) {
    console.error(error);
    return 'error';
  }
}
