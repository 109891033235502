import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
// utils
import { fData } from 'src/utils/format-number';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// assets
import { countries } from 'src/assets/data';
// _mock
import { _addressBooks, TESTDATA } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
  RHFSelect,
  RHFMultiCheckbox,
} from 'src/components/hook-form';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { endpoints, getFetcher, postFetcher, postPramsFetcher } from 'src/utils/axios';
import { NEWSelect } from 'src/components/hook-form/new-searchble';
import SerchOption from 'src/components/hook-form/serchOption';

// ----------------------------------------------------------------------

export default function SystemNewEditForm({ currentUser, masterData }) {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();
  const [productFieldList, setProductFieldList] = useState([]);
  const [systemMasterList, setSystemMasterList] = useState([]);

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    company: Yup.string().required('Company is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    role: Yup.string().required('Role is required'),
    zipCode: Yup.string().required('Zip code is required'),
    avatarUrl: Yup.mixed().nullable().required('Avatar is required'),
    // not required
    status: Yup.string(),
    isVerified: Yup.boolean(),
  });

  const NewMasterSchema = Yup.object().shape({
    item_id: Yup.string().required('Master Data ID is required'),
    // not required
    status: Yup.string(),
    isVerified: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      city: currentUser?.city || '',
      role: currentUser?.role || '',
      email: currentUser?.email || '',
      state: currentUser?.state || '',
      status: currentUser?.status || '',
      address: currentUser?.address || '',
      country: currentUser?.country || '',
      zipCode: currentUser?.zipCode || '',
      company: currentUser?.company || '',
      avatarUrl: currentUser?.avatarUrl || null,
      phoneNumber: currentUser?.phoneNumber || '',
      isVerified: currentUser?.isVerified || true,
    }),
    [currentUser]
  );

  const defaultmasterData = useMemo(
    () => ({
      item_id: masterData?.item_id || '',
    }),
    [masterData]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema, NewMasterSchema),
    defaultValues,
    defaultmasterData,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFetcher(endpoints.systemSettings.productFeildList);
        setProductFieldList(response);
        // console.log('response', response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // const onSubmit = handleSubmit(async (data) => {
  //   const masterDataList = {
  //     item_id: data.item_id,
  //   };
  //   const params = { item_id: data.item_id };

  //   try {
  //     await new Promise((resolve) => setTimeout(resolve, 500));

  //     const systemData = await postPramsFetcher(
  //       endpoints.systemSettings.systemMasterSearchList,
  //       null,
  //       params
  //     );
  //     console.log('systemData', systemData);
  //     setSystemMasterList(systemData);
  //     reset();
  //     enqueueSnackbar(masterData ? 'Update success!' : 'Create success!', {
  //       autoHideDuration: 7000,
  //       anchorOrigin: { vertical: 'top', horizontal: 'center' },
  //       sx: {
  //         width: '400px',
  //       },
  //     });
  //     console.log('systemMasterList', systemMasterList);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // });

  const onSubmit = handleSubmit(async (data) => {
    console.log('data', data);
    const params = { item_id: data.item_id };
    const config = {
      Headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      const systemData = await postPramsFetcher(
        endpoints.systemSettings.systemMasterSearchList,
        params,
        config
      );
      setSystemMasterList(systemData);
      reset();
      enqueueSnackbar(masterData ? 'Update success!' : 'Create success!', {
        autoHideDuration: 7000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        sx: {
          width: '400px',
        },
      });
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const from = useBoolean();
  const to = useBoolean();

  const upMd = useResponsive('up', 'md');

  const { invoiceFrom, invoiceTo } = values;

  // console.log('productFieldList', productFieldList);
  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sx={{ mr: 1 }}>
              <Item>
                {/* <NEWSelect
                  name="systemMaster"
                  label="System Master"
                  onChange={onSubmit} 
                  options={systemMasterList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                /> */}
                {/* <NEWSelect
                  name="systemMaster"
                  label="System Master"
                  onChange={(selectedValue) => onSubmit({ item_id: selectedValue })}
                  options={systemMasterList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                /> */}
                <SerchOption
                  name="systemMaster"
                  label="System Master"
                  options={systemMasterList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Item>
            </Grid>
            <Grid item xs={6} md={6}>
              <Item>
                {/* <Stack alignItems="center" sx={{ mt: 0 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={from.onTrue}
                  >
                    {!currentUser ? 'Add New' : 'Save Changes'}
                  </LoadingButton>
                </Stack> */}
              </Item>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>

      <Grid container spacing={1}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            />

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  {productFieldList.map((item) => (
                    <Grid item xs={6} md={6} key={item.id}>
                      <Item>
                        <Card sx={{ height: 60 }}>
                          <Typography sx={{ mt: 2, textAlign: 'left', pl: 2 }}>
                            {item.field_name}
                          </Typography>
                        </Card>
                      </Item>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={6} md={4}>
                  {productFieldList.map((item) => (
                    <Grid item xs={6} md={6} key={item.id}>
                      <Item>
                        <Card sx={{ height: 60 }}>
                          <FormControlLabel
                            control={<Switch checked={item.enable} />}
                            // label="Enable"
                            sx={{ flexGrow: 1, mt: 1 }}
                          />
                        </Card>
                      </Item>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Stack alignItems="center" sx={{ mt: 5 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ width: '30%' }}
              >
                {!currentUser ? 'Save & Next' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

SystemNewEditForm.propTypes = {
  currentUser: PropTypes.object,
  masterData: PropTypes.object,
};
