import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// _mock
import { INVOICE_STATUS_OPTIONS } from 'src/_mock';
// components
import Label from 'src/components/label';
import Scrollbar from 'src/components/scrollbar';
//
import InvoiceToolbar from 'src/sections/invoice/invoice-toolbar';

// ----------------------------------------------------------------------

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    textAlign: 'right',
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function PosDetails({ invoice }) {
  const renderList = (
    <Box sx={{ maxHeight: '400px', overflowY: 'scroll', mt: 1 }}>
      <TableContainer sx={{ overflow: 'unset', mt: 1 }}>
        <Scrollbar>
          <Table sx={{ minWidth: 400 }}>
            <TableHead>
              <TableRow>
                <TableCell width={10}>#</TableCell>

                <TableCell sx={{ typography: 'subtitle2' }}>Product Info</TableCell>

                <TableCell>Qty</TableCell>

                <TableCell align="right">Unit price</TableCell>

                <TableCell align="right">Discount</TableCell>

                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            {/* 2 */}
            <TableBody>
              <TableRow>
                <TableCell>{2}</TableCell>

                <TableCell>
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">Product ABC</Typography>
                  </Box>
                </TableCell>

                <TableCell>10</TableCell>

                <TableCell align="right">
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">100</Typography>
                  </Box>
                </TableCell>

                <TableCell align="right">
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">5</Typography>
                  </Box>
                </TableCell>

                <TableCell align="right">950</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{1}</TableCell>

                <TableCell>
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">Product BCD</Typography>
                  </Box>
                </TableCell>

                <TableCell>150</TableCell>

                <TableCell align="right">
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">1000</Typography>
                  </Box>
                </TableCell>

                <TableCell align="right">
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">100</Typography>
                  </Box>
                </TableCell>

                <TableCell align="right">135000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Box>
  );

  return (
    <>
      <Card
        sx={{
          pt: 1,
          px: 1,
          border: 'none',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {renderList}
      </Card>
    </>
  );
}

PosDetails.propTypes = {
  invoice: PropTypes.object,
};
