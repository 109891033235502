import numeral from 'numeral';

// ----------------------------------------------------------------------


export function fNumber1(number) {
  return number ? numeral(number).format('0,0.00') : '';

}

export function fNumber(number) {
  return numeral(number).format();
}


export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fCurrencyLkr(number) {
  return number ? numeral(number).format('LKR 0,0.000') : '';
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function formatDate  (dateString)  {
  if (!dateString) return '-';
  
  const date1 = new Date(dateString);
  // const delivery_date = new Date(dateString);
  // const trail_date = new Date(dateString);

  const day = String(date1.getDate()).padStart(2, '0');
  const month = String(date1.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date1.getFullYear();
  
  return `${day}-${month}-${year}`;
};
export function roundUpValue(value) {
  if (value !== undefined && value !== null && value !== "null" && !Number.isNaN(value)) {
    return Math.ceil(value);
  }
  return "-";
}


// export function roundUpValue(value, decimalPlace) {
//   const parsedValue = parseFloat(value);
//   if (!Number.isNaN(parsedValue)) {
//     const multiplier = 10 ** decimalPlace;
//     return Math.ceil(parsedValue * multiplier) / multiplier;
//   }
//   return "-";
// }

