import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: null,
  selected_location: null,
  selected_location_name: null,
  error: null,
  checkinStatus: false,
  newStatus: null,
  checkInCheckoutStatus: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      console.log('loginSuccess', state);
      state.isLoggedIn = true;
      state.user = action.payload;
      state.error = null;
    },
    updateLocation: (state, action) => {
      console.log('loginSuccess', state);
      state.isLoggedIn = true;
      state.selected_location = action.payload;
      state.error = null;
    },

    updateLocationName: (state, action) => {
      console.log('loginSuccess', state);
      state.isLoggedIn = true;
      state.selected_location_name = action.payload;
      state.error = null;
    },
    updateCheckinCheckout: (state, action) => {
      console.log('loginSuccess', state);
      // state.isLoggedIn = true;
      state.checkinStatus = action.payload;
      state.error = null;
    },

    loginFailure(state, action) {
      console.log('loginFailure', state);
      state.isLoggedIn = false;
      state.user = null;
      state.error = action.payload;
    },
    logout(state) {
      console.log('logout', state);
      state.isLoggedIn = false;
      state.user = null;
      state.error = null;
      state.selected_location = null;
      state.checkinStatus = null;
    },
    clearLoginSuccess: (state) => {
      state.loginSuccess = null;
    },
    updateNewStatus: (state, action) => {
      state.newStatus = action.payload;
    },
    updateCheckInCheckoutStatus: (state, action) => {
      state.checkInCheckoutStatus = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logout,
  clearLoginSuccess,
  updateLocation,
  updateLocationName,
  updateCheckinCheckout,
  updateNewStatus,
  checkInCheckoutStatus,
  updateCheckInCheckoutStatus
} = authSlice.actions;

export default authSlice.reducer;
