// ----------------------------------------------------------------------

export const ITEMSTATUSLINE = [
  {
    key: 'batch',
    value: 'Batch',
  },
  {
    key: 'serial',
    value: 'Serial Number wise',
  },
  {
    key: 'group',
    value: 'Group by',
  },
];

export const PRICELIST = [
  {
    key: 'selling_price',
    value: 'Selling Price',
  },
  {
    key: 'purchase_price',
    value: 'Purchase Price',
  },
  {
    key: 'discount_price',
    value: 'Discounted Price',
  },
];

export const ITEM_STATUS = [
  {
    key: 'inventoryItem',
    value: 'Inventory Item',
  },
  {
    key: 'sellableItem',
    value: 'Sellable Item',
  },
];

export const DELIVERY = [{ key: 'deliveryType', value: 'Delivery' }];
export const TRANSPOTATION = [{ key: 'transportation', value: 'Transportation' }];
export const SHIPPINGINSTRUCTION = [{ key: 'shippingInstruction', value: 'Shipping Instruction' }];
export const SHIPPINGTYPE = [
  {
    key: 'individualshipment',
    value: 'Individual shipment',
  },
  {
    key: 'collectiveshipment',
    value: 'Collective shipment',
  },
  {
    key: 'transportationchain',
    value: 'Transportation chain',
  },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  { group: 'Clothing', classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather', 'Accessories'] },
  { group: 'Tailored', classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats', 'Apparel'] },
  { group: 'Accessories', classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'] },
];

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Men', value: 'Men' },
  { label: 'Women', value: 'Women' },
  { label: 'Kids', value: 'Kids' },
];
export const MATERIAL_MASTER_OPTIONS = [
  { label: 'Short', value: 'Short' },
  { label: 'Lable', value: 'Label' },
];

export const MANUFACTURE = [{ label: 'Manufacture', value: 'Manufacture' }];

export const ITEMRETURN = [{ key: 'itemReturns', value: 'Item Returns' }];

export const TRANSPOTATIONLIST = [
  { key: 'Hot', value: 'Hot', label: 'Hot' },
  { key: 'Cold', value: 'Cold', label: 'Cold' },
];

export const RETURNPOLICY = [
  {
    key: 'within7Dayreturns',
    value: 'Within Working 7 Day Returns',
  },
  {
    key: 'exchangeProducts',
    value: 'Exchnage Product Within 3 Days',
  },
];
export const REFUNDPOLICY = [
  {
    key: 'goodsExchangeWithinaday',
    value: 'Good Exchange With in a Day',
  },
  {
    key: 'cashBackRefund',
    value: 'Cash Back Refund With in a Day',
  },
];
export const WARRANTDESCRIPTION = [
  {
    key: '3monthWarranty',
    value: '03 Month Warranty',
  },
  {
    key: '6monthwarranty',
    value: '06 Month Warranty',
  },
];

export const ITEMTYPE = [
  { key: 'inventoryItem', value: 'Inventory Item' },
  { key: 'salesItem   ', value: 'Sales Item  ' },
  { key: 'purchaseItem', value: 'Purchase Item' },
];

export const ITEMVISIBILITY = [
  { key: 'e-commerceVisible', value: 'E-commerce Visible' },
  { key: 'e-commerceSellable', value: 'E-commerce Sellable' },
  { key: 'pOSsellable', value: 'POS sellable' },
  { key: 'wholesalesellable', value: 'Wholesale sellable' },
];

export const ITEMMANAGEBY = [
  { key: 'fifo', value: 'Fifo' },
  { key: 'wAverage', value: 'W.Average' },
  { key: 'lifo', value: 'Lifo' },
];

export const ITEMSTATUS = [
  {
    key: 'active',
    value: 'Active',
  },
  {
    key: 'inactive',
    value: 'Inactive',
  },
];

export const ITEMDEFINITION = [
  {
    key: 'itemidentifiers',
    value: 'Item identifiers',
  },
];

export const ITEMSPECIFICATION = [{ key: 'itemSpecification', value: 'Item Specification' }];

export const TAXLIABLE = [{ key: 'taxLiable', value: 'Tax Liable' }];

export const PRODUCT_CATEGORY_OPTIONS = ['Shose', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'violet', label: 'Violet' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
];

export const PRODUCT_SIZE_OPTIONS = [
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' },
  { value: '10.5', label: '10.5' },
  { value: '11', label: '11' },
  { value: '11.5', label: '11.5' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

// Sumedha
// export const ITEMSTATUSLINE = [{ classify: ['Batch', 'Serial Number wise', 'Group by'] }];
// Fire

export const COUNTRYOFORIGIN = [{ classify: ['Sri Lanka', 'India', 'China'] }];
export const PRODUCT_CHECKOUT_STEPS = ['Cart', 'Billing & address', 'Payment'];

export const TESTDATA = [{ classify: ['Test Data 1', 'Test Data 2', 'Test Data 3'] }];
