// import PropTypes from 'prop-types';
// // @mui
// import TableRow from '@mui/material/TableRow';
// import TableCell from '@mui/material/TableCell';
// //
// import EmptyContent from '../empty-content';

// // ----------------------------------------------------------------------

// export default function TableNoData({ notFound, sx }) {
//   return (
//     <TableRow>
//       {notFound ? (
//         <TableCell colSpan={12}>
//           <EmptyContent
//             filled
//             title="No Data"
//             sx={{
//               py: 10,
//               ...sx,
//             }}
//           />
//         </TableCell>
//       ) : (
//         <TableCell colSpan={12} sx={{ p: 0 }} />
//       )}
//     </TableRow>
//   );
// }

// TableNoData.propTypes = {
//   notFound: PropTypes.bool,
//   sx: PropTypes.object,
// };

import PropTypes from 'prop-types';
// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
//
import EmptyContent from '../empty-content';
import { LinearBuffer, LinearWithValueLabel } from '../loading-screen';



// ----------------------------------------------------------------------

export default function TableNoData({ notFound, sx ,tableLoading}) {
  return (
    <TableRow>
      {notFound || tableLoading ? (
        // <Box
        // sx={{
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center',
        //   height: '100%',
        // }}>
        // {/* <TableCell colSpan={12}> */}
        //   {/* <EmptyContent
        //     filled
        //     title="No Data"
        //     sx={{
        //       py: 10,
        //       ...sx,
        //     }}
        //   /> */}
        //   <CircularProgress  />
        //    {/* <LinearBuffer /> */}
        //   {/* <LinearWithValueLabel /> */}
        // {/* </TableCell> */}
        // </Box>

      
  
        <TableCell colSpan={12} sx={{ p: 10, pl:60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={50} />
      </TableCell>
        
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

TableNoData.propTypes = {
  notFound: PropTypes.bool,
  sx: PropTypes.object,
  tableLoading:PropTypes.bool,
};
