import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// _mock
import { PRODUCT_STOCK_OPTIONS } from 'src/_mock';
// api
import { useGetProducts } from 'src/api/product';
// components
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import PosTableToolbar from '../pos-table-toolbar';
import PosTableRow from '../pos-table-row';
import PosTableFiltersResult from '../pos-table-filters-result';
import PosDetails from '../pos-details';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Product' },
  // { id: 'createdAt', label: 'Create at', width: 160 },
  { id: 'totalQuantity', label: 'Quantity' },
  { id: 'price', label: 'Price' },
  // { id: 'publish', label: 'Publish', width: 110 },
  { id: '' },
];

const PUBLISH_OPTIONS = [
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
];

const defaultFilters = {
  name: '',
  publish: [],
  stock: [],
};

// ----------------------------------------------------------------------

export default function OverviewPOSView() {
  const router = useRouter();

  const table = useTable();

  const settings = useSettingsContext();

  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);

  const { products, productsLoading, productsEmpty } = useGetProducts();

  const confirm = useBoolean();

  useEffect(() => {
    if (products.length) {
      setTableData(products);
    }
  }, [products]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 60 : 80;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || productsEmpty;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.product.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.product.details(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Card
                sx={{
                  border: 'none',
                  width: '100%',
                  margin: '0 auto',
                  mb: 2,
                  height: '400px',
                  overflow: 'hidden',
                }}
              >
                <PosTableToolbar
                  filters={filters}
                  onFilters={handleFilters}
                  //
                  stockOptions={PRODUCT_STOCK_OPTIONS}
                />

                <PosDetails />
              </Card>

              <Grid item xs={9}>
                <Card sx={{ width: '132.8%', margin: '10 auto' }}>
                  <Box sx={{ width: '100%', margin: '0 auto' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={6}>
                        <Item>
                          <Box sx={{ width: '100%' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'left' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">Sub Total</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'right' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">5000.50</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'left' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">Item Discount</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'right' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">250.12</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'left' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">Tax 10%</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'right' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">150.12</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'left' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">Item Count</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'right' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle2">2</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item>
                          <Box sx={{ width: '100%' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6} />
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'right' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="subtitle1">Sub Total</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                              <Grid item xs={6} />
                              <Grid item xs={6}>
                                <Item sx={{ textAlign: 'right' }}>
                                  <Box sx={{ maxWidth: 560 }}>
                                    <Typography variant="h6">25874.12</Typography>
                                  </Box>
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Box sx={{ position: 'fixed' }}>
                <Box sx={{ width: '100%' }}>
                  <Grid
                    container
                    rowSpacing={1}
                    spacing={0}
                    columnSpacing={{ xs: 1, sm: 1, md: 0 }}
                  >
                    <Grid item xs={12}>
                      <Item sx={{ height: '120%' }}>
                        <Button
                          component={RouterLink}
                          // href={paths.dashboard.product.new}
                          variant="contained"
                          size="large"
                          sx={{
                            borderRadius: 1,
                            width: '100%',
                            height: '100%',
                            fontSize: '20px',
                          }}
                          startIcon={<Iconify icon="material-symbols:shopping-cart-checkout" />}
                        >
                          Checkout
                        </Button>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <Button
                          component={RouterLink}
                          href={paths.dashboard.product.new}
                          variant="contained"
                          size="large"
                          sx={{
                            borderRadius: 1,
                            width: '100%',
                            height: '100%',
                            fontSize: '15px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                          startIcon={<Iconify icon="icon-park-twotone:find" />}
                        >
                          Product Lookup
                        </Button>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <Button
                          component={RouterLink}
                          href={paths.dashboard.product.new}
                          variant="contained"
                          size="large"
                          sx={{
                            borderRadius: 1,
                            width: '100%',
                            height: '100%',
                            fontSize: '15px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                          startIcon={<Iconify icon="eva:options-2-outline" />}
                        >
                          Options
                        </Button>
                      </Item>
                    </Grid>
                    <Grid item xs={6} rowSpacing={0} spacing={0}>
                      <Item>
                        <Button
                          component={RouterLink}
                          href={paths.dashboard.product.new}
                          variant="contained"
                          size="large"
                          sx={{
                            borderRadius: 1,
                            width: '100%',
                            height: '100%',
                            fontSize: '15px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                          startIcon={<Iconify icon="mdi:clock-outline" />}
                        >
                          Clock Out
                        </Button>
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item>
                        <Button
                          component={RouterLink}
                          href={paths.dashboard.product.new}
                          variant="contained"
                          size="large"
                          sx={{
                            backgroundColor: '#f53333',
                            color: '#000000',
                            borderRadius: 1,
                            width: '100%',
                            height: '100%',
                            fontSize: '15px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                          startIcon={<Iconify icon="material-symbols:delete" />}
                        >
                          Cancel Invoice
                        </Button>
                      </Item>
                    </Grid>

                    <Card sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}>
                          <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 0 }}>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  1
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  2
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  3
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  4
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  5
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  6
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  7
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  8
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  // href={paths.dashboard.product.new}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '60%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  9
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  .
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  0
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  00
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                  startIcon={
                                    <Iconify
                                      icon="teenyicons:up-solid"
                                      sx={{ alignSelf: 'center', ml: 1 }}
                                    />
                                  }
                                />
                              </Item>
                            </Grid>
                            <Grid item xs={8}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  Enter
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                  startIcon={
                                    <Iconify
                                      icon="teenyicons:down-solid"
                                      sx={{ alignSelf: 'center', ml: 1 }}
                                    />
                                  }
                                />
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  Back
                                </Button>
                              </Item>
                            </Grid>
                            <Grid item xs={4}>
                              <Item>
                                <Button
                                  component={RouterLink}
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    height: '150%',
                                    fontSize: '15px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  Clear
                                </Button>
                              </Item>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Card>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { name, stock, publish } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (product) => product.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (stock.length) {
    inputData = inputData.filter((product) => stock.includes(product.inventoryType));
  }

  if (publish.length) {
    inputData = inputData.filter((product) => publish.includes(product.publish));
  }

  return inputData;
}
